<template>
  <div class="pool">
    <div class="top">
      <div class="left">
        <div class="title">{{title + '人数占比'}}</div>
        <Pie @clickPie="clickPie" class="pie" :colors="[]" :dataList="pieDataList"></Pie>
      </div>
      <div class="right">
        <div class="title">{{title + '人数统计'}}</div>
        <div class="rs-list">
          <div class="rs-item" @click="chooseCount(index)" :class="{'rs-item-active': item.active}" v-for="(item, index) in countList" :key="index">
            <div class="label">
            {{item.name}}
            </div>
            <div class="num">
            {{item.value}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="title">员工信息明细  {{dataList.length}}人</div>
      <div class="tb">
        <fm-table-new
          :columns="columns"
          :auto-height="true"
          border="row"
          :data-list="dataList"
          :stripe="false"
          emptyPlaceholder="-">
        </fm-table-new>
      </div>
    </div>
  </div>
</template>

<script>
import Pie from './pie'

export default {
  components: {
    Pie,
  },
  props: {
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    },
    countList: {
      type: Array,
      default: () => {
        return []
      }
    },
    title: {
      type: String
    }
  },
  methods: {
    clickPie (parm) {
      this.$emit('chooseCount', parm.dataIndex)
    },
    chooseCount (index) {
      this.$emit('chooseCount', index)
    }
  },
  computed: {
    pieDataList () {
      return this.countList.map(v => {
        return Object.assign({}, v, {selected: v.active})
      })
    },
    needOrgName2 () {
      return this.$store.getters.sysConfig['needOrgName2']
    },
    columns () {
      let data = this.baseColumns1.filter(v => this.needOrgName2 || v.field !== 'zg')
      data = data.filter(v => this.workerTableType1 || v.workerTableType1 !== true)
      data.forEach(v => {
        if (v.filters) {
          v.filters.push({
            value: '__null',
            label: '值为空'
          })
        }
      })
      return data
    },
    baseColumns1 () {
      return [
        { title: '姓名', field: 'name', fixed: 'left', sort: true, width: 120},
        { title: '性别', field: 'sex', sort: true, width: 120, filters: [{
          label: '男',
          value: '男'
        },
        {
          label: '女',
          value: '女'
        }]
        },
        { title: '民族', width: 120, field: 'clan', sort: true, filters: this.$store.getters.selectDatas['worker_clan_list'].map(v => {
          return {
            label: v.label,
            value: v.key
          }
        })},
        {
          title: '职务',
          field: 'orgRoleLabel',
          width: 120,
          sort: true
        },
        {
          title: '出生年月', field: 'birth', width: 250, sort: true, dataType: Date, filterRange: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.birth ? rowData.birth.slice(0, 10) : '-')
          }
        },
        {
          title: '年龄', field: 'age', sort: true, dataType: Number, width: 120, filterRange: true
        },
        { title: '政治面貌', width: 120,field: 'politicalStatus', sort: true,
        filters: this.$store.getters.selectDatas['worker_political_status_list'].map(v => {
          return {
            label: v.label,
            value: v.key
          }
        })},
        { title: '身份证号码', field: 'idNo', sort: true, width: 184 },
        { title: '是否在编', width: 120,field: 'type', sort: true, filters: ['在编', '非在编'].map(v => {
          return {
            label: v,
            value: v
          }
        })},
        { title: '在岗状态',width: 120, field: 'jobTypeC', sort: true, filters: this.$store.getters.selectDatas['worker_zg_status'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '工龄', field: 'maritalStatus', sort: true, dataType: Number, width: 120, filterRange: true},
        // { title: '最高学历', width: 120,field: 'zgEducation', sort: true, filters: this.$store.getters.selectDatas['worker_education_list'].map(v => {
        //   return {
        //     label: v.key,
        //     value: v.key
        //   }
        // })},
        { title: '是否港澳台及外籍人士',width: 120, field: 'graduatedSchool', sort: true, filters: [{
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }]},
        {
          title: '参加工作时间', field: 'correctionDate', sort: true, dataType: Date, filterRange: true,
          width: 250,
          render: (h, rowData) => {
            return h('div', rowData && rowData.correctionDate ? rowData.correctionDate.slice(0, 10) : '-')
          }
        },
        {
          title: '进入本单位时间', field: 'entryDate', sort: true, dataType: Date, filterRange: true,
          width: 250,
          render: (h, rowData) => {
            return h('div', rowData && rowData.entryDate ? rowData.entryDate.slice(0, 10) : '-')
          }
        },
        { title: '人员类别',width: 120, field: 'jobType', sort: true, filters: this.$store.getters.selectDatas['worker_job_type'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '是否特殊人才',width: 120, field: 'agreementCode', sort: true, filters: [{
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }]},
        { title: '入职方式',width: 120, field: 'employmentForm', sort: true, filters: this.$store.getters.selectDatas['employment_form'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '离职方式',width: 120, field: 'discipline', sort: true, filters: this.$store.getters.selectDatas['worker_resign_list'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '职称',width: 120, field: 'jobLevel', sort: true, filters: [{value: '员级', label: '员级'}, {value: '助理级', label: '助理级'}, {value: '中级', label: '中级'}, {value: '副高级', label: '副高级'}, {value: '正高级', label: '正高级'}]},
        { title: '现任岗位类别',width: 120, field: 'jobTitleTypeText', sort: true, filters: this.$store.getters.selectDatas['job_title_type'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        // { title: '现任岗位层级',width: 120, field: 'xrgwTopLevel', sort: true, filters: [{value: '初级', label: '初级'}, {value: '中级', label: '中级'}, {value: '高级', label: '高级'}]},
        // { title: '现任岗位职称级别',width: 120, field: 'xrgwMLevel', sort: true, filters: [{value: '员级', label: '员级'}, {value: '助理级', label: '助理级'}, {value: '中级', label: '中级'}, {value: '副高级', label: '副高级'}, {value: '正高级', label: '正高级'}]},
        // { title: '现任岗位等级',width: 120, field: 'xrgwJobTitleTitle', sort: true,
        //   filterMethod (parm) {
        //     return parm.row.xrgwJobTitleTitle === parm.value
        //   }
        // },
        // { title: '现任岗位级别',width: 120, field: 'xrgwjb', sort: true },
        // { title: '现任岗位时间', field: 'xrgwsj', width: 250, sort: true, dataType: Date, filterRange: true, },
        { title: '是否专业技术人员',width: 120, field: 'address', sort: true, filters: [{
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }]},
        { title: '专业技术人员专业名称',width: 120, field: 'shuangjiantiao', sort: true },
        { title: '专业技术人员专业类别',width: 120, field: 'email', sort: true, filters: this.$store.getters.selectDatas['zyjsryzylb'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '是否卫生专业技术人员',width: 120, field: 'jobTitle', sort: true, filters: [{
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }]},
        { title: '学历', field: 'education', width: 120, sort: true, filters: this.$store.getters.selectDatas['worker_education_list'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '学位', field: 'degree', width: 120, sort: true, filters: this.$store.getters.selectDatas['worker_edu_degree'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '籍贯', field: 'hometown', width: 120, sort: true },
        { title: '联系电话', field: 'phone', width: 120, sort: true },
        { title: '个人身份', field: 'sf',width: 120, sort: true, filters: this.$store.getters.selectDatas['worker_identity_list'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })}].map(v => {
        v.search = true
        return v
      })
    }
  }
}
</script>

<style scoped lang="less">
.pool {
  width: 100%;
  max-height: 100%;
  height: 100%;
  padding: 10px;
}
.top {
  height: 200px;
  width: 100%;
  display: flex;
  .left {
    width: 350px;
    height: 100%;
    background: #fff;
    margin-right: 10px;
    position: relative;
    .pie {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
  .right {
    height: 100%;
    flex: 1;
    background: #fff;
  }
}
.title {
  height: 40px;
  padding-left: 10px;
  line-height: 40px;
  font-size: 16px;
}
.rs-list {
  height: calc(100% - 40px);
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  .rs-item {
    padding: 10px;
    cursor: pointer;
    background: #999;
    margin-bottom: 10px;
    margin-right: 10px;
    min-width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(250, 250, 250, 1);
    border-radius: 8px;
  }
  .rs-item-active {
    background: #FBBAC4 !important;
    color: #fff;
  }
}
.bottom {
  margin-top: 10px;
  height: calc(100% - 210px);
  background: #fff;
  display: flex;
  flex-direction: column;
  .tb {
    width: 100%;
    flex: 1;
  }
}
</style>
